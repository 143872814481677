import * as React from "react";
import loadable from '@loadable/component'

const Layout = loadable(() => import("../layouts"))
const Header = loadable(() => import("../components/homepage-header/homepage-header"))
const Insurance = loadable(() => import("../components/homepage-insurances/homepage-insurances"))



const Quote = () => (

  <Layout pageTitle="Warranty Insurance">
    
    <Header type="quote" />
    <Insurance type="quote"/>
    
  </Layout>

);

export default Quote;
